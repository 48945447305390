@font-face {
  font-family: 'spradling';
  src:  url('fonts/spradling.eot?hkindr');
  src:  url('fonts/spradling.eot?hkindr#iefix') format('embedded-opentype'),
    url('fonts/spradling.ttf?hkindr') format('truetype'),
    url('fonts/spradling.woff?hkindr') format('woff'),
    url('fonts/spradling.svg?hkindr#spradling') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="sp-"], [class*=" sp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'spradling' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-arrow-left:before {
  content: "\e900";
}
.sp-arrow-right:before {
  content: "\e901";
}
.sp-chevron-left:before {
  content: "\e902";
}
.sp-chevron-right:before {
  content: "\e903";
}
.sp-minus:before {
  content: "\e904";
}
.sp-plus:before {
  content: "\e905";
}
.sp-times-o:before {
  content: "\e906";
}
.sp-times:before {
  content: "\e907";
}
