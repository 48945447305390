@include media-breakpoint-down(md) {
  .home-container-description {
    padding: 0.5rem;
  }

  .home-our-products {
    height: 344px;
  }

  .home-our-projects {
    height: 344px;
  }

  .section-container-description {
    padding: 0 3rem;
  }
}
