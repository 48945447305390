.donwload--successfully_container {
  border-radius: 20px;
  background-color: #f1f1f2;
}

.donwload--successfully_title {
  font-size: 2.25rem;
  border-color: gray;
}

.donwload--successfully_divider {
  width: 80px;
  border-width: 1.5px;
}