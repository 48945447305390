.search--projects--by__category-title {
  display: flex;
  align-items: center;
  height: 100px;
}

.custom_class_main_container_row_filters {
  
}

.main_content_indivisual_filters {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-family: "Gramatika", Helvetica;

  .col1_indivisual_filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .row-filter-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      background-color: #ECEBEB;
      border-radius: 20px;
      padding: 0 1rem 0 1rem;
      line-height: 30px;
      position: relative;
  
      .custom_class_delete_filter {
        padding: 0.2rem;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
  
    
    }
  }

  .col2_indivisual_filters {
    display: flex;
    justify-content: flex-end;
    min-width: fit-content;
  }
  
  .custom_class_clear_filters_btn {
    border: none;
    outline: none;
    background-color: transparent;
    justify-self: flex-end;
    height: fit-content;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .main_content_indivisual_filters {
    flex-direction: column-reverse;
    gap: 8px;
    
    .col2_indivisual_filters {
      justify-content: flex-start;
    }
  }
}