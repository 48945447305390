.download--images_btn {
  background-color: #545b62;
  color: white;
  border: none;
  outline: none;
  font-size: 1.15rem;
}

.download--images_btn:hover {
  color: #545b62;
  background-color: lightgray !important;
  border: none;
  font-size: 1.15rem;
  outline: none;
}