.cicle-expired {
  width: 15px;
  height: 15px;
}

.bg-gray {
  background-color: #999999;
}

.expiration-certificate--tooltip {
  font-size: 9px;
  padding-left: 2px;
  cursor: default;
}

.smaller-fs {
  font-size: 12px;
}

.expiration-certificate--tooltip-message .tooltip-inner {
  background-color: rgb(228, 228, 228, 1);
  border-radius: 10px;
  color: #5A5A5A;
  font-family: Helvetica, sans-serif;
  position: absolute;
  width: 240px;
  max-width: 240px;
  text-align: left;
  padding: 4px 10px;
  top: -18px;
  left: 30px;
}

.expiration-certificate--tooltip-message-mobile-sustainability .tooltip-inner {
  background-color: rgb(228, 228, 228, 1);
  border-radius: 10px;
  color: #5A5A5A;
  font-family: Helvetica, sans-serif;
  width: 240px;
  max-width: 240px;
  text-align: left;
  padding: 4px 10px;
}

.expirate-certification--icon-info {
  font-size: 7px;
  position: relative;
  top: -5px;
  left: -2px;
}

.expirate-danger:hover {
  color: red;
}

.expirate-success:hover {
  color: rgb(0, 161, 0);
}

.expirate-warning:hover {
  color: rgb(255, 187, 0);
}

.expiration-certificate--tooltip-message .tooltip-inner::before {
  content: "";
  display: block;
  background-color: rgb(228, 228, 228, 1);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: -20px;
  clip-path: polygon(100% 0, 16% 100%, 100% 100%);
}

.expiration-certificate--tooltip-message-mobile-sustainability .tooltip-inner::before {
  content: "";
  display: block;
  background-color: rgb(228, 228, 228, 1);
  width: 20px;
  height: 20px;
  clip-path: polygon(100% 0, 16% 100%, 100% 100%);
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: none;
}

.tooltip .arrow::before {
  border-top-color: rgb(228, 228, 228, 1) !important;
}

.expiration-certificate--tooltip-message .arrow {
  display: none !important;
}