// Specific app spaces
$header-height: 76px;
$header-height-lg-up: 76px;

// Bootstrap extra variables
$grid-gutter-width-half: $grid-gutter-width / 2;


// Grid
// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/
$grid-extra-small-gutter-width: 8px;
$grid-small-gutter-width:       16px;
$grid-medium-gutter-width:      24px;
$grid-gutter-width-base:        $grid-gutter-width !default;
$grid-large-gutter-width:       35px;
$grid-extra-large-gutter-width: 40px;

$grid-gutter-widths: (
  extra-small: $grid-extra-small-gutter-width,
  small: $grid-small-gutter-width,
  medium: $grid-medium-gutter-width,
  regular: $grid-gutter-width-base,
  large: $grid-large-gutter-width,
  extra-large: $grid-extra-large-gutter-width
);

// Animation
$transition-duration: 100ms !default;
$transition-timing-function: ease-out !default;

// Colors
$gray: #7B7D80;
$gray-light: #9B9B9B;
$gray-dark: #5A5A5A;
$orange: #FF9900;
$dark-desaturated-blue: #024C77;
$red: #DD0000;

$primary: $orange;
$secondary: $dark-desaturated-blue;

$skeleton-bg: #f1f1f1;

// Theme colors
$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Type
$font-family-sans-serif:      Helvetica, sans-serif;
$font-family-base:            $font-family-sans-serif;
$font-size-xs:                $font-size-sm * 0.8;
